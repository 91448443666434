import React, { useState } from 'react';
import { Button, ListItem } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import './Content.css';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ArchiveIcon from '@mui/icons-material/Archive';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SendIcon from '@mui/icons-material/Send';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import { styled, alpha } from '@mui/material/styles';




export default function Content({ genContent, token, project_id, RetrieveContent, SwitchTab, CreateContent, project }) {
    
  const documentPostSubTypes = ['Twitter', 'Facebook', 'LinkedIn'];
  const documentDocSubTypes = ['Essay']
  const documenttypes = ['Post', 'Blog', 'Document'];
  const [docLength, setDocLength] = useState(5);
    
  const [docDialog, setDocDialog] = useState(0);

  const [documentTitle, setDocumentTitle] = useState();
  const [documentType, setDocumentType] = useState('Post');
  const [documentSubType, setDocumentSubType] = useState('Facebook');
  const [loading, setLoading] = useState(0)
  const [displayquestionpopup, setdisplayquestionpopup] = useState(0)
  const [questionText, setQuestionText] = useState('')
  const [questionResponse, setQuestionResponse] = useState('')
  const [questionError, setQuestionError] = useState('')
  const [loadingq_a, setloadingq_a] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
    
  const changeDocument = (doctype) =>
  {
    setDocumentType(doctype)

    switch (doctype)
    {
      case 'Post':
        setDocumentSubType('Facebook')
      case 'Document':
        setDocumentSubType('Essay')
    }
  }

  const handleClickQuestionResponse = () => {



    if (questionText.trim()=='')
      {
        setQuestionError('You may have forgotten to ask a question!')
      return        
    }

    {
      questionText != '' &&
       setloadingq_a(1)
      fetch(process.env.REACT_APP_API_HOST + '/generate/question/' + project_id + '/' + questionText + '/' + token)
        .then((response) => response.json())
        .then((resources) =>
        {
          setQuestionResponse(resources['answer']);
          setloadingq_a(0)
          setQuestionError('')
          }
          
        );
    }
  };
  
  const handleSummaryClick = (event) => {
    
    setLoading(1);
    fetch(process.env.REACT_APP_API_HOST + '/generate/summarize/' + project_id + '/' + token)
      .then((response) => response.json())
      .then((resp) => {
        RetrieveContent();
        setLoading(0);
      })
  };


  const handleIdeasClick = (type) => {
    handleClose();
    setLoading(1);

    fetch(process.env.REACT_APP_API_HOST + '/generate/ideas/' + project_id + '/' + type + '/'  + token)
      .then((response) => response.json())
      .then((resp) => {
        RetrieveContent();
        setLoading(0);
      }
      );
  };

  const handleSaveDocumentClick = (event, content_id) => {
    setLoading(1);
    setDocDialog(0);
    fetch(process.env.REACT_APP_API_HOST + '/document/create' + '/' + token, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'project_id': project_id, 'content_id': content_id, 'name': documentTitle, 'type': documentType, 'subtype': documentSubType })
    })
      .then((response) => response.ok)
      .then(() => {
                    
        setDocumentTitle('');
        setDocumentType('Post');
        setDocumentSubType('Facebook');
         
        setLoading(0);
        SwitchTab('3');

      }
      );
  };

  

  const DeleteContent = (content_id) => {
    fetch(process.env.REACT_APP_API_HOST + '/content/delete/' + content_id + '/' + token)
      .then((response) => response.ok)
      .then(() =>
        RetrieveContent()
      );
  }

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading == 1}
  
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Drawer
        anchor='right'
        open={displayquestionpopup == 1}
        onClose={() => {setdisplayquestionpopup(0)
          
        
        }}

      >
        <Box sx={{ width: '600px' }} spacing={2}>
          <AppBar position="static">
            <Typography variant="h6" component="div" sx={{ padding: '2px', flexGrow: 1 }}>
              Create with AI
            </Typography>
          </AppBar>

          <Stack spacing={1} sx={{ padding: '3px' }}>
            <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
              <Typography variant='caption'>Level: {project.level}</Typography>
              <Typography variant='caption'>Tone: {project.tone}</Typography>
            </Stack>
            {questionError != '' &&
              <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt', mb: '5px' }}>
                {questionError}
              </Typography>
            }
            <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '15px', mb: 0 }}>
              Unleash the power of curiosity: engage your resources to create new content.
            </Typography>

            <TextField label="What can I help with" multiline maxRows={10}
              value={questionText}
              onChange={(event) => (setQuestionText(event.target.value ))}
              

            ></TextField>

            <Button variant='contained' disabled={loadingq_a == 1} endIcon={<SendIcon />} onClick={handleClickQuestionResponse}>Submit</Button>
            {loadingq_a == 1 &&
              <Box sx={{ width: '100%', alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <br />
                <br />
                <CircularProgress size={80} />
              </Box>
            }
            {loadingq_a == 0 &&
              <Typography variant="body2" sx={{ textAlign: 'left' }}>

                {questionResponse}
              </Typography>}
            <Paper sx={{ position: 'absolute', bottom: 0, width: '100%' }} elevation={3}>
              <BottomNavigation
                showLabels
              >
                <BottomNavigationAction label="Keep" onClick={() => {
                  {
                    questionResponse != '' &&
                      CreateContent(questionResponse, 'Create', questionText);
                    setdisplayquestionpopup(0);
                    
                  }
                }} icon={<ArchiveIcon />} />
              </BottomNavigation>
            </Paper>
          </Stack>

        </Box>
      </Drawer>
      < Dialog open={docDialog != 0} onClose={() => { setDocDialog(0) }} >
        <DialogTitle>Create Document
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack spacing={2}>
              <TextField sx={{ width: '350px', mt: 2, fontSize: 14 }}
                label="Title"
                value={documentTitle}
                onChange={(event) => { setDocumentTitle(event.target.value) }}
                multiline
              ></TextField>
              <FormControl fullWidth sx={{ minWidth: 150 }}>
                <InputLabel id="text-level-select-label">Document Type</InputLabel>
                <Select
                  variant='outlined'
                  label="Document Type"
                  labelId='text-tone-select-label2'
                  id='text-tone-select2'
                  value={documentType}
                  onChange={(event) => { changeDocument(event.target.value) }}

                >
                  {documenttypes.map((type) => (
                    <MenuItem key={'selecttype-' + type} value={type} >{type}</MenuItem>

                  ))}
                </Select>
              </FormControl>
              {documentType == 'Post' &&
                <FormControl fullWidth sx={{ minWidth: 150 }}>
                  <InputLabel id="text-level-select-label">Post Type</InputLabel>
                  <Select
                    variant='outlined'
                    label="Post Type"
                    labelId='text-post-type-select-label2'
                    id='text-post-type-select2'
                    value={documentSubType}
                    onChange={(event) => { setDocumentSubType(event.target.value) }}

                  >
                    {documentPostSubTypes.map((type) => (
                      <MenuItem key={'selecttype-' + type} value={type} >{type}</MenuItem>

                    ))}
                  </Select>
                </FormControl>
              }
              {documentType == 'Document' &&
                <FormControl fullWidth sx={{ minWidth: 150 }}>
                  <InputLabel id="text-document-select-label">Type</InputLabel>
                  <Select
                    variant='outlined'
                    label="Document Type"
                    labelId='text-document-select-label'
                    id='text-post-type-select2'
                    value={documentSubType}
                    onChange={(event) => { setDocumentSubType(event.target.value) }}

                  >
                    {documentDocSubTypes.map((type) => (
                      <MenuItem key={'doctype-' + type} value={type} >{type}</MenuItem>

                    ))}
                  </Select>
                </FormControl>
            }
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setDocDialog(0) }}>Cancel</Button>
          <Button onClick={(e) => handleSaveDocumentClick(e, docDialog)} >Save</Button>
        </DialogActions>
      </Dialog>
      <div className='Content-buttons'>
        <Stack sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="caption" sx={{ textAlign: 'center', color: 'steelblue', mb: '5px', fontSize: '12pt' }}>
            Embark on your journey promptly through these enticing options.
          </Typography>
          <Stack direction="row" spacing={1} sx={{ textAlign: "center", alignItems: "center" }} >
            <Button variant="contained" size="medium" disabled={loading == 1} onClick={(event) => handleSummaryClick(event)}>
              Summarize  </Button>
            <Button variant="contained" size="medium" disabled={loading == 1} onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}            
              id="ideas-customized-button"
              aria-controls={open ? 'ideas-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              
            > Ideas</Button>
            <StyledMenu
              id="ideas-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'ideas-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            ><MenuItem onClick={(event) => { handleIdeasClick('Article') }} disableRipple>
                
                Article Ideas
              </MenuItem>
              <MenuItem onClick={(event) => { handleIdeasClick('SocialMedia') }} disableRipple>

                Social Media Ideas
              </MenuItem>

            </StyledMenu>

            <Button variant="contained" size="medium" disabled={loading == 1} onClick={(event) => {

              setdisplayquestionpopup(1);
              setQuestionError('');
              
            }}>
              Create...
            </Button>



          </Stack>
        </Stack>
      </div>
      <br />
     
      <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
        {genContent.map((response, index) => (
          <Card key={'crd' + response.content_id} sx={{ minWidth: 275, maxWidth: 300 }}>
            <CardHeader
                             
              action={
                <Box>
                  <Tooltip title="Create from Content">         
                  <IconButton sx={{ p: '3px' }} onClick={() => {
                    setDocDialog(response.content_id);
                    setDocumentTitle(response.title);
                  }}>
                    <NoteAddIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Content">  
                  <IconButton sx={{ p: '3px' }} aria-label="delete" onClick={() => { DeleteContent(response.content_id) }} >
                    <DeleteIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                    </Tooltip>
                                
                </Box>
              }
              title={response.type}
              titleTypographyProps={{ sx: { fontSize: 16, textAlign: 'left' }, color: "text.secondary" }}
              subheader={
                <Stack>
                  {response.created_datetime}
                                
                  <Stack direction='row' spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                    <Typography variant="caption" sx={{ textAlign: 'left' }}>
                      Level: {response.level}
                    </Typography>
                    <Typography variant="caption" sx={{ textAlign: 'left' }}>
                      Tone: {response.tone}
                    </Typography>

                  </Stack>
                </Stack>
              }
              subheaderTypographyProps={{ sx: { fontSize: 12, textAlign: 'left' }, color: "text.secondary" }}
            >
                              
            </CardHeader>
            <CardContent >
                              
              {response.type == 'Create' &&
                               
                <div>
                  <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                    Ask: {response.prompt}
                  </Typography>
                  <br />
                </div>
              }

              <Typography variant="body2" sx={{ textAlign: 'left' }}>
                          
                {response.content}
              </Typography>
                              
                             
            </CardContent>
                        
          </Card>
                      
                    

        ))}
      </Stack>
    </div>

  )
}