import React, { useState, useEffect } from 'react';
import { Button, ListItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './ResearchAgent.css'
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LanguageIcon from '@mui/icons-material/Language';
import { orange, pink,purple } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CancelIcon from '@mui/icons-material/Cancel';

export default function ResearchAgent({ token, project, switchTab }) {
    
    //const [randomHelperText, setRandomHelperText] = useState('sds');
    const [requestText, setRequestText] = useState('');
    const refreshTime = 15000 //How frequently you want to refresh the data, in ms
    const [agentDocuDialog, setAgentDocuDialog] = useState('')
    const [refImage, setRefImage] = useState('')
    const [agentRequests, setAgentRequests] = useState([])

    const retrieve_ref_image = (image_id) => {
        if (image_id) {
            fetch(process.env.REACT_APP_API_HOST + '/agent/request/result/image/' + token + '/' + image_id)
                .then((response) => response.json())
                .then((resp) => {
                    return (
                        setRefImage(resp['data'])
                            
                    
                    )
                }
                );
        }
    }
    

    const RetrieveAgentRequests = () => {
        fetch(process.env.REACT_APP_API_HOST + '/agent/request/' + token + '/' + project.project_id)
            .then((response) => response.json())
            .then((agentrequests) =>
                setAgentRequests(agentrequests)
                //this.setState(() => {
                //    return { agentRequests: agentrequests }
            // })
            
            );
    }

   /* const reteiveHelperText = (prj) =>
    {
        
        if (prj.tags)
        {
            var tag = prj.tags[Math.floor(Math.random() * prj.tags.length)];
            return 'For example: search for ' + tag;
        }
        return '';

    }*/

    const refreshresults = () => {
        if (agentRequests.length > 0) {
            var items = agentRequests.filter(request => request.status != 'complete' && request.status != 'failed');
        //console.log(agentRequests); 
            if (items.length > 0) {
            
                RetrieveAgentRequests();        
            }
        }
         
        
    }

    useEffect(() => {
       RetrieveAgentRequests();
        
    }, [project])

    
    useEffect(() => {
        const comInterval = setInterval(refreshresults, refreshTime); //This will refresh the data at regularIntervals of refreshTime
        return () => clearInterval(comInterval) //Clear interval on component unmount to avoid memory leak

    }, [agentRequests])
        
    

    const createResource = (result_id,name, reference, type,subtype) =>
    {
        fetch(process.env.REACT_APP_API_HOST + '/resource/create/' + project.project_id + '/' + token, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'result_id': result_id,  'content': '', 'name': name, 'type': type, 'subtype': subtype, 'reference': reference  })
        })
            .then((response) => response.ok)
            .then(() => {

                //RetrieveResources();

            }
            );
        }

    
    const DeleteAgentRequest = (agentrequest_id) => {

        
        fetch(process.env.REACT_APP_API_HOST + '/agent/request/delete/' + token + '/' + agentrequest_id)
            .then((response) => response.ok)
            .then(() =>
                RetrieveAgentRequests()
            );
        
    }

    const createAgentRequest = () => {
        
        if (requestText.trim() != '') {
            fetch(process.env.REACT_APP_API_HOST + '/agent/request/create/' + token, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'project_id': project.project_id, 'requestText': requestText, })
            })
                .then((response) => response.ok)
                .then(() => {

                    setRequestText('');
                    RetrieveAgentRequests();
                });
        }
    }


    return (
     
        <div className='agent-main'>
            <div className='agent-header'>
                <div className='agent-title-img'>
                    <img src={require('./../Images/robot_head.png')} height={'90px'} />

                </div>
                <div className='agent-title'>
                    <Typography variant="caption" sx={{ color: 'steelblue', fontSize: '12pt' }}>
                        I can assist with finding resources for your research or you can add your own resources using the <Button variant="text" sx={{m:0,p:0, fontWeight:'bold'}} onClick={()=>{switchTab('1')}}>RESOURCES</Button> tab.
                    </Typography>
                </div>
        
                <div className='agent-search'>
                    <Stack direction={'row'} spacing={1} sx={{  textAlign: 'center', justifyContent: 'center' }}>

                        <TextField
                            required
                            margin="dense"
                            id="name"
                            label="What can I help you find"
                            type="text"
                            sx={{ width: 550 }}
                            spellcheck="true"
                            multiline
                            maxRows={8} 
                            value={requestText}
                            onChange={(event) => { setRequestText(event.target.value) }}

                        ></TextField>
                        <Box >
                            <IconButton sx={{ mt: '15px' }} onClick={() => { createAgentRequest() }}>
                                <SendIcon  ></SendIcon>
                            </IconButton>
                        </Box>
                    </Stack>
                </div>
                
                    
               
            </div>
            <Dialog open={agentDocuDialog != ''} sx={{ m: '0px', p: '0px' }}>
                <DialogActions sx={{ m: '0px', p: '0px' }}>
                    <IconButton>
                        <CancelIcon onClick={() => { setAgentDocuDialog('') }} />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ m: '0px', p: '0px' }}>
                    <DialogContentText sx={{ m: '0px', p: '0px' }}>
                        {retrieve_ref_image(agentDocuDialog)}
                        < img src={refImage} width={'600px'} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {agentRequests.length > 0 &&
                <div className='agent-results'>
                    
                            {agentRequests.map((reqItem, index) => (
                                <Paper sx={{ m: '10px', p: '10px', backgroundColor: '#F4F5F7' }}>
                                    <Stack spacing={1}>
                                        <Stack direction={'row'} spacing={4} width={'100%'}>                                            
                                            <Box width={'75%'} sx={{
                                                textAlign: 'left', alignItems: 'left'
                                            }}>
                                            <Typography variant="caption" sx={{ textAlign: 'left', alignItems: 'left', color: "grey", mr: "5px", fontSize: '11pt' }}>
                                                    {reqItem.requestText}
                                                </Typography>
                                                {reqItem.status == 'failed' &&
                                                    <Typography variant="caption" sx={{ textAlign: 'left', alignItems: 'left', color: "firebrick", mr: "5px", fontSize: '11pt' }}>
                                                         -- Failed!
                                                    </Typography>
                                                }
                                            </Box>
                                            <Box width={'25%'} sx={{ alignSelf: 'stretch', textAlign: 'right', alignItems: 'right' }}>
                                                {reqItem.status == 'complete' &&
                                                    <Tooltip title='Delete All Results'>
                                                        <IconButton size="small" >
                                                            <DeleteIcon onClick={() => { DeleteAgentRequest(reqItem.agentrequest_id) }} sx={{ fontSize: 20 }} />
                                                        </IconButton>
                                                    </Tooltip> 
                                            }
                                                    {reqItem.status == 'failed' &&
                                                    <Tooltip title='Delete All Results'>
                                                        <IconButton size="small" >
                                                            <DeleteIcon onClick={() => { DeleteAgentRequest(reqItem.agentrequest_id) }} sx={{ fontSize: 20 }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </Box>
                               
                                        </Stack>
                                        {reqItem.status != 'complete' &&
                                        
                                            reqItem.status != 'failed' &&

                                                <LinearProgress />
                                        
                                            
                                        }
                                        
                                        {reqItem.status == 'complete' &&
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Resource</TableCell>
                                                            <TableCell align="center">Type</TableCell>
                                                            <TableCell align="left">Reference</TableCell>
                                                            <TableCell align="center"></TableCell>
                                            
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    
                                                        {reqItem.SearchResults &&
                                                    
                                                            reqItem.SearchResults.map((row, index) => (
                                                                <TableRow
                                                                    key={row.url}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        <Tooltip title={row.desc}>
                                                                            {row.name}
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {row.subtype == 'web' &&
                                                                            <Tooltip title='Web'>
                                                                                <LanguageIcon color="primary" />
                                                                            </Tooltip>
                                                                        }

                                                                        {row.subtype == 'vectordb' &&
                                                                            <Tooltip title='Reference'>
                                                                                <MenuBookIcon sx={{ color: purple[500] }} />
                                                                            </Tooltip>
                                                                        }
                                                                        {row.subtype == 'video' &&
                                                                            <Tooltip title='Video'>
                                                                                <VideoCameraBackIcon color="success" />
                                                                            </Tooltip>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        
                                                                        {row.subtype == 'vectordb' &&
                                                                            <Box sx={{ m: '0px', p:'0px'}}>
                                                                                <Link href='#' onClick={() => { setAgentDocuDialog(row.ref_id) }}>{row.url}
                                                                                    <OpenInNewIcon sx={{ fontSize: 10 }} /></Link>
                                                                                
                                                                            </Box>

                                                                        }
                                                                        {row.subtype != 'vectordb' &&
                                                                            <a href={row.url} target="_blank" rel="noopener noreferrer">{row.url}
                                                                                <OpenInNewIcon sx={{ fontSize: 10 }} />
                                                                            </a>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Tooltip title='Add to Resource'>
                                                                            <IconButton onClick={() => { createResource(row.id, row.name, row.url, row.type, row.subtype) }}>
                                                                                <LibraryAddIcon sx={{ fontSize: 20 }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                        </Stack>
                    </Paper>
                ))
                        }
                
                </div>
            }
          
            </div >
        
    )

}