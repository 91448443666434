import React, { useState } from 'react';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import ReCaptchaV2 from 'react-google-recaptcha';
import './Login.css';


async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_API_HOST + '/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default function Login({ setToken }) {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [failed, setFailed] = useState();
    const [form, setForm] = useState();

    const handleToken = (token) => {
        setForm( token )

    }

    const handleExpire = () => {
        setForm( null )
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            username,
            password
        });

        if (token['token'] == 'failed') {
            setFailed(1)
         }
        else { setFailed(0); setToken(token['token']); }

        
    }

    return (

        <div className="login-main-grid"> 
            <div className="login-header">
                <img src={require('./../Images/header_logo.png')} height={'148px'} />
            </div>
            <div className='login-tagline'>
                <Typography variant='h6' >
                    Your AI-powered tool for productive content creation and academic research
                </Typography></div>
            <div className="login-wrapper" >
                <div className='login-contents'>
                <Typography variant='h5' sx={{mt:'10px', mb:'15px'}}>
                    Log In
                    </Typography>
                    
            {failed == 1 &&
                <Typography variant='caption' color='error'>
                Authentication Failed! Please check username and password.
                </Typography>
            }
        <form >
            <label>
                Username<br/>
                    <input type="text" onChange={(e) => setUserName(e.target.value)} />
                    </label>
                    <br />
                    <br />
            <label>
                Password<br/>
                    <input type="password" onChange={(e) => setPassword(e.target.value)} />
            </label>
                
                        <br />
                        <br />
                        <ReCaptchaV2 sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleToken}
                            onExpire={handleExpire} />
                        <br/>
                        <Button type="submit" variant='contained' size='small' onClick={handleSubmit} disabled={form==null}>Submit</Button>
                        <br />
                        <br />
            
            </form>
                </div>
            </div>
            
            <div className="login-header2"></div>
            <div className='login-body'>

                <iframe src='https://www.youtube.com/embed/T2wtFShHr4g?feature=player_embedded'
                    width="640" height="360"
                    frameborder='0'
                    allow='autoplay; encrypted-media'
                    allowfullscreen
                    title='video'
                />
            </div>
            <div className='login-footer'>                
                <b>Disclaimer: Pre-Alpha Testing with LLM Integration</b><br/>

                This web application is currently in the pre-alpha testing phase and includes integration with Language Model Models (LLMs). As such, users should expect potential instability, incomplete features, and inaccuracies in generated content. Your participation in testing is voluntary, and you acknowledge that any data or feedback provided may be used for further development. By accessing and using this application, you agree to hold harmless the developers and creators from any issues or consequences arising from its usage. Your valuable insights and patience during this testing phase are greatly appreciated.
                
            </div>
        </div>
    )
}