
import React, { useState } from 'react';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import ReCaptchaV2 from 'react-google-recaptcha';
import './TestRequestForm.css';

async function requestform(formdata) {
    return fetch(process.env.REACT_APP_API_HOST + '/requestform', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formdata)
    })
        .then(data => data.json())
}


export default function TestRequestForm() {

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [comment, setComment] = useState();
    const [failed, setFailed] = useState(0);
    const [form, setForm] = useState();
    const [submitted, setSubmitted] = useState(0);

    const handleToken = (token) => {
        setForm(token)

    }

    const handleExpire = () => {
        setForm(null)
    }

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            setEmail(mail)
        }
        alert("You are writing invalid email address!")
        return (false)
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await requestform({
            firstName,
            lastName,
            email,
            comment

        });

        setSubmitted(1)
    }


    return (
        <div className="main-grid">
            <div className="request-header">
                <img src={require('./../Images/header_logo.png')} height={'148px'} />
            </div>
            <div className='request-tagline'>
                <Typography variant='h6' >
                    Your AI-powered tool for productive content creation and academic research
                </Typography></div>
            
            
            {submitted > 0 &&
                <div className='request-wrapper-submitted'>
                    <br/><br/>
                <Typography variant='h5' sx={{ mt: '8px', mb: '15px' }}>
                    Request has been sent.
                    </Typography>
                    </div>
            }
            {submitted == 0 &&
                <div className="request-wrapper" >
                <div className='request-contents'>
                    <Typography variant='h6' sx={{ mt: '8px', mb: '15px' }}>
                        Access request:
                    </Typography>
                    <form >
                        <table>
                            <tr>
                                <td>First Name</td>
                                <td><input require type="text" size="40" onChange={(e) => setFirstName(e.target.value)} /></td>
                            </tr>
                            <tr>
                                <td>Last Name</td>
                                <td><input require type="text" size="40" onChange={(e) => setLastName(e.target.value)} /></td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td><input require type="email" size="40"  onChange={(e) => setEmail(e.target.value)} /></td>
                            </tr>
                        </table>
                        <label>
                            Comment<br/>
                            <textarea require type="text" cols="60" rows="5"   onChange={(e) => setComment(e.target.value)} />
                       </label>

                        <br />
                        <br />
                        <ReCaptchaV2 sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleToken}
                            onExpire={handleExpire} />
                        <br />
                        <Button type="submit" variant='contained' size='small' onClick={handleSubmit} disabled={form == null}>Submit</Button>
                        <br />
                        <br />

                        </form>
                    </div>
                </div>
                    }
                
            
            <div className='request-footer'>
                <b>Disclaimer: Pre-Alpha Testing with LLM Integration</b><br />

                This web application is currently in the pre-alpha testing phase and includes integration with Language Model Models (LLMs). As such, users should expect potential instability, incomplete features, and inaccuracies in generated content. Your participation in testing is voluntary, and you acknowledge that any data or feedback provided may be used for further development. By accessing and using this application, you agree to hold harmless the developers and creators from any issues or consequences arising from its usage. Your valuable insights and patience during this testing phase are greatly appreciated.

            </div>
        </div>
            
        
       
    );
}