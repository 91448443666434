import React from 'react';
import { Component, useState } from 'react';
//import { ScrollView } from 'react-native';
import { Button, ListItem } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Chip from '@mui/material/Chip';
import SendIcon from '@mui/icons-material/Send';
import InputLabel from '@mui/material/InputLabel';
import './Main.css';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Tooltip from '@mui/material/Tooltip';
import Login from './Login/Login';
import Document from './Documents/Document';
import Content from './Content/Content';
import Resources from './Research/Resources';
import ResearchAgent from './Research/ResearchAgent';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import { purple, pink } from '@mui/material/colors';
import Feedback from './Support/Feedback';
import Bugs from './Support/Bugs';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ShareIcon from '@mui/icons-material/Share';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CampaignIcon from '@mui/icons-material/Campaign';
import HelpPopover from './Support/HelpPopover';


class App extends Component {
 

  constructor() {
    super();
   

    this.state = {
      projects: [], value: "0",
      projectId: '', responses: [], loading: 0, openResourceDialog: 0, resourceText: '',
      resourceName: '', selectedContectMore: 0, resourceType: 'text', resourceReference: '',
      projectDialog: 0, projectName: '',  projectEdit: 0, projectlevel: 'professional', projecttone: 'friendly', projectTags: [],
      projectEditDialog: 0, tagText: '', aitags: [],
      levels: ['1st grade', '2nd grade', '3rd grade', '4th grade', '5th grade', '6th grade', '7th grade', '8th grade', 'high school', 'college', 'professional'],
      tones: ['professional', 'friendly', 'upbeat', 'bubbly', 'informative', 'urgent'], user: {},
      token: localStorage.getItem('token'),
      loadingq_a: 0, documents: [], projectError: '', questionError: '', project: {}, hasResources: false
    };
    
  }


 
  ClearProject() {
    this.setState({ projectName: '', projectlevel: 'professional', projecttone: 'friendly', projectTags: [], tagText: '', projectError: '' })
  }

  SetProjectDialog(project) {
    this.setState({ projectName: project.name, projectlevel: project.level, projecttone: project.tone, projectTags: project.tags, projectError: '' })
  }

  

  UpdateProjects(project_id) {
    this.setState({ projectError: '' }, () => {

      {
        this.state.projectName == '' &&
          this.setState({ projectError: 'Missing Valid Project Name' })

      }
    });


    {
      this.state.projectName != '' &&
        fetch(process.env.REACT_APP_API_HOST + '/project/update/' + project_id + '/' + this.state.token, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 'name': this.state.projectName, 'level': this.state.projectlevel, 'tone': this.state.projecttone, 'tags': this.state.projectTags })
        })
          .then((prj) => {
            this.setState(() => {
              return { projectEditDialog: 0, project: prj }
            }, () => {
          
          
              this.RetrieveProjects();
              /*, projects: this.state.projects.concat(prj), project: prj*/

            }
            )
          });
    }
  }

  UpdateUser() {
    fetch(process.env.REACT_APP_API_HOST + '/user/update' + '/' + this.state.token, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.user)
    })
  }


  RetrieveProjects() {

    fetch(process.env.REACT_APP_API_HOST + '/projects/' + this.state.user.user_id + '/' + this.state.token)
      .then((response) => response.json())
      .then((projects) =>
        this.setState(() => {
          return { projects: projects }
        }, () => {
          this.state.projects.filter(project => project.project_id === this.state.projectId).map(project => (
            this.setState({ project: project })
          ))
        })
      );
  }


 


 


  RetreiveContent() {
    fetch(process.env.REACT_APP_API_HOST + '/content/' + this.state.projectId + '/' + this.state.token)
      .then((response) => response.json())
      .then((resp) =>
        this.setState(() => {
          return { responses: resp }
        })
      );
  }

 
  DeleteProject(project_id) {
    fetch(process.env.REACT_APP_API_HOST + '/project/delete/' + project_id + '/' + this.state.token)
      .then((response) => response.ok)
      .then(() => {
        this.setState({ 'projectId': '', projectEditDialog: 0 })
        this.RetrieveUser()
        this.RetrieveProjects()
      }
      );
  }

  RetrieveDocuments() {
    fetch(process.env.REACT_APP_API_HOST + '/documents/' + this.state.projectId + '/' + this.state.token)
      .then((response) => response.json())
      .then((docs) =>
        this.setState(() => {
          return { documents: docs }
        })
      );
  }

  //temp fix
   RetrieveResources () {
    fetch(process.env.REACT_APP_API_HOST + '/resources/' + this.state.projectId + '/' + this.state.token)
      .then((response) => response.json())
      .then((res) =>     
        this.setState(() => {
          return { hasResources: res.filter(resource => resource.status == 'ready').length > 0 }
         })
      );
  }

  RetrieveUser() {
    fetch(process.env.REACT_APP_API_HOST + '/user/' + this.state.token)
      .then((response) => response.json())
      .then((usr) =>
        this.setState(() => {
          return { user: usr, projectId: usr.default_project_id, projects: [], resources: [], responses: [], documents: [] }
        }, () => {
          {
            this.state.user.user_id == '' &&
              this.setState(() => { return { 'token': null } });

          }


          {
            usr.default_project_id != '' &&
              this.RetrieveProjects();
            this.RetrieveResources();
            this.RetreiveContent();
            this.RetrieveDocuments();
           // this.RetrieveAgentRequests();
          }
        })
      );
  }




  componentDidMount() {
    
    if (this.state.token) this.RetrieveUser();
  }

 
  

  render() {
    
    const BASE_URL = process.env.REACT_APP_API_HOST

    const RetrieveDocumentsHandle = () => {
      this.RetrieveDocuments();
    }

    const RetrieveContentHandle = () => {
      this.RetreiveContent();
    }


    const SetHasResource = (value) =>
    {
      this.setState({ hasResources: value })
      }

    const CreateProjects = () => {

      this.setState({ projectError: '' }, () => {

        {
          this.state.projectName == '' &&
            this.setState({ projectError: 'Missing Valid Project Name' })
        
        }
      });


      {
        this.state.projectName != '' &&
          fetch(process.env.REACT_APP_API_HOST + '/project/create/' + this.state.user.user_id + '/' + this.state.token, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'name': this.state.projectName, 'level': this.state.projectlevel, 'tone': this.state.projecttone, 'tags': this.state.projectTags })
          })
            .then((response) => response.json())
            .then((prj) => {
              this.RetrieveUser();
              this.setState(() => {
                return { projectId: prj.project_id, projectDialog: 0 }
              }, () => {
           
                this.RetrieveProjects();
              
              });
            });
      }

      /*, projects: this.state.projects.concat(prj), project: prj*/

    }
       
    

    

    const setToken = (token) => {
      this.setState(() => { return { 'token': token } }, () => {
        this.RetrieveUser();
        localStorage.setItem('token', token);
      });
      
    }

    const Logout = () => {
      this.setState({ user: {}, token: null, projectId: '', projects: [], resources: [], responses: [], documents: [] })
      localStorage.removeItem('token')
    }


    const handleChange = (event, newValue) => {
      this.setState({ 'value': newValue });

    };

    const handleListItemClick = (event, pid) => {

      this.setState(() => { return { projectId: pid, questionText: '', questionResponse: '' } }, () => {
        this.state.projects.filter(project => project.project_id === this.state.projectId).map(project => (
          this.setState({ project: project })
        ))

        this.RetrieveResources()
        this.RetreiveContent()
        this.RetrieveDocuments();
        //this.RetrieveAgentRequests();

      });
      
    };

  
    const handleSwitchTab = (value) => {
      this.setState(() => { return { value: value } }, () => { this.RetrieveDocuments(); })
    }


    const CreateContent = (content, type, prompt) => {
      fetch(process.env.REACT_APP_API_HOST + '/content/create' + '/' + this.state.token, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 'level': this.state.project.level, 'tone': this.state.project.tone, 'project_id': this.state.projectId, 'type': type, 'content': content, 'prompt': prompt })
      })
        .then((response) => response.json())
        .then((resp) => {
          this.setState(() => {
            return { responses: this.state.responses.concat(resp) }
          }, () => { handleSwitchTab('2') });

        }
        );
    }

 
    const ColorButton = styled(Button)(({ theme }) => ({
      color: theme.palette.getContrastText(purple[200]),
      backgroundColor: purple[200],
      '&:hover': {
        backgroundColor: purple[500],
      },
      color:'primary'
    }));

    if (!this.state.token) {
      return <Login setToken={setToken} />
    }

    
    return (
      <div className="App">
        
        <header className="App-header">
          <div className='Title'>
            <div className='Title-Left'>
               
              <img src={require('./Images/header_logo_small.png')} height={'90px'} />
            </div>
            <div className='Title-Right'>
              <Stack direction='row' spacing={1}>
                {false &&
                  <IconButton sx={{ width: 20, height: 20, p: '0px', m: '0px' }} >
                    <SettingsIcon />
                  </IconButton>
                }
                <Bugs token={this.state.token}/>
                <Tooltip title="Logout">
                  <IconButton sx={{ width: 30, height: 30, p: '0px', m: '0px' }} onClick={Logout} >
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
                <Avatar sx={{ bgcolor: deepOrange[500], width: 30, height: 30, p: '0px', m: '0px' }}>{this.state.user.initials}</Avatar>
               
              </Stack>
            </div>
          </div>
          <div className='Page-Bar-1'>
            <div className='Bar-Header'>
           
              Projects
              
              <Tooltip title="Create a new Project"><IconButton size="small" color="secondary" onClick={(event) => {
                this.setState(() => { return { projectDialog: 1 } });
                this.ClearProject();
                
              }}  >
                <AddCircleOutlineIcon fontSize="inherit" />
              </IconButton></Tooltip> 
              <Dialog open={this.state.projectDialog == 1} onClose={() => { this.setState(() => { return { projectDialog: 0 } }) }}  >
                <DialogTitle>Create New Project</DialogTitle>

                <DialogContent sx={{ overflowY: 'visible' }}>
                  <DialogContentText>
                    <Stack spacing={1} >
                      <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt', mb: '5px' }}>
                        {this.state.projectError}
                      </Typography>
                      <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt' }}>
                        Imprint your project with a name that etches itself into memory.
                      </Typography>
                      <TextField
                        required
                        sx={{ width: 450 }}
                        variant="outlined"
                        label="Project Name"
                        value={this.state.projectName}
                        onChange={(event) => (this.setState(() => {
                          return ({ projectName: event.target.value });
                        }))}
                      >
                      </TextField>
                      <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt' }}>
                         AI crafts text that matches the project's chosen education level and tone.
                      </Typography>
                      <Stack direction='row' spacing={2} sx={{ mt: '10px' }}>

                        <FormControl fullWidth sx={{ minWidth: 150 }}>
                          <InputLabel id="text-level-select-label">Text Level</InputLabel>
                          <Select
                            variant='outlined'
                            label="Text Level"
                            labelId='text-level-select-label'
                            value={this.state.projectlevel}
                            onChange={(event) => { this.setState(() => { return { projectlevel: event.target.value } }) }}
                            sx={{ overflowY: 'visible' }}

                          >
                            {this.state.levels.map((level) => (
                              <MenuItem key={'select1' + level} value={level}>{level}</MenuItem>

                            ))}
                          </Select>

                        </FormControl>

                        <FormControl fullWidth sx={{ minWidth: 150 }}>
                          <InputLabel id="text-tone-select-label">Text Tone</InputLabel>
                          <Select
                            variant='outlined'
                            label="Text Tone"
                            labelId='text-tone-select-label'
                            value={this.state.projecttone}
                            
                            onChange={(event) => { this.setState(() => { return { projecttone: event.target.value } }) }}

                          >
                            {this.state.tones.map((tone) => (
                              <MenuItem key={'select1' + tone} value={tone}>{tone}</MenuItem>

                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                      <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt' }}>
                        Use Tags to pinpoint the key theme(s) that define your project's essence (upto 5).
                      </Typography>
                      <Stack direction='row' spacing={0}>

                        <TextField
                          sx={{ width: 200 }}
                          variant="outlined"
                          label="tag"
                          value={this.state.tagText}
                          onChange={(event) => (this.setState(() => {
                            return ({ tagText: event.target.value });
                          }))}
                        >

                        </TextField>
                        <Box sx={{ mt: '10px' }}>
                          <Tooltip title="Add Tag">
                            <IconButton onClick={() => {

                              this.setState({ projectTags: this.state.projectTags.concat(this.state.tagText) }, () => {
                                this.setState({ tagText: '' })
                              });
                            }}
                              disabled={this.state.projectTags.length==5}
                            >
                              <SendIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Stack>
                      <Stack variant="row" spacing={0}>
                        <Typography sx={{ mb: 0 }} variant='caption'>Tags:</Typography>
                        <Box sx={{ border: 1, height: '50px', mt: 0 }} >
                          {this.state.projectTags.map((tag, index) => (
                            <Chip label={tag} onDelete={() => {
                              const secs = this.state.projectTags;
                              secs.splice(index, 1);
                              this.setState({ projectTags: secs });
                            }} />
                          ))}
                        </Box>
                      </Stack>
                    </Stack>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => { this.setState(() => { return { projectDialog: 0 } }) }}>Cancel</Button>
                  <Button onClick={() => CreateProjects()} >Save</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className='Bar-Main'>
              <List component="nav" aria-label="mailbox folders" sx={{ width: '100%', minWidth: 50, maxWidth: 360 }}>
                {
                  this.state.projects.map((project) => {
                    return (
                      <ListItem sx={{ padding: '1px', margin: 0 }}
                        key={project.project_id}
                      >
                        {project.project_id == this.state.user.default_project_id ? < StarIcon sx={{ fontSize: '14pt' }} />
                          : <Tooltip title="Make Favorite">
                            <IconButton sx={{ m: '0px', p: '0px' }} onClick={() => {
                              var usr = this.state.user
                              usr.default_project_id = project.project_id
                              this.setState(() => { return { user: usr } }, () => {
                                this.UpdateUser()
                              })
                            }} >
                            
                              < StarBorderIcon sx={{ fontSize: '14pt' }} variant='outlined' color="disabled" /></IconButton>
                          </Tooltip>
                        }
                        <ListItemButton key={project.project_id} sx={{ padding: 0, margin: 0 }}
                          selected={this.state.projectId === project.project_id}
                          onClick={(event) => handleListItemClick(event, project.project_id)} >
                          
                         
                          <ListItemText sx={{ padding: 0, margin: 0 }} primary={project.name} />

                        </ListItemButton>
                       
                      </ListItem>
                    );
                  })
                }
                <Divider />
              </List>
            </div>
            <div className='Bar-Footer'>
              <Divider sx={{ mb: '12px' }} />
              <Feedback user_id={this.state.user.user_id} token={this.state.token} />

              
              <div className='disclaimer'>
                <b>Disclaimer: Pre-Alpha LLM-Integrated Web App</b><br />

                This app is in pre-alpha with LLM integration. Expect instability, incomplete features, and inaccuracies. Your voluntary testing implies no liability on developers. Thank you for your understanding.
              </div>
            </div>
          </div>
          <div className='Page-Heading '>
            {this.state.projectId != '' &&
              this.state.projects.filter(project => project.project_id == this.state.projectId).map(project => (

                <Stack direction="row" spacing={2}>
                  <Stack sx={{ textAlign: 'left' }} direction="row" spacing={1}>
                    <Stack sx={{ mr: '25px' }}>
                      <div>{project.name}
                        <Tooltip title="Edit Project">
                          <IconButton onClick={() => {
                            this.setState({ projectEditDialog: 1, projectTag: project.tags })
                            this.SetProjectDialog(project)
                          }}>
                            <EditIcon />
                          </IconButton>  </Tooltip>
                        <Dialog open={this.state.projectEditDialog == 1} onClose={() => { this.setState(() => { return { projectEditDialog: 0, projectTag: [] } }) }} >
                          <DialogTitle>Edit Project
                            <Tooltip title="Delete Project">
                              <IconButton sx={{ ml: '5px' }}>
                                <DeleteIcon onClick={() => { this.DeleteProject(project.project_id) }} />
                              </IconButton>
                            </Tooltip>
                          </DialogTitle>

                          <DialogContent>
                            <DialogContentText>
                              <Stack spacing={1} >
                                <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt', mb: '5px' }}>
                                  {this.state.projectError}
                                </Typography>
                                <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt' }}>
                                  Not vibing with the name? No worries, swap it out for something you love!
                                </Typography>
                                <TextField
                                  required
                                  sx={{ width: 450 }}
                                  variant="outlined"
                                  label="Project Name"
                                  value={this.state.projectName}
                                  onChange={(event) => (this.setState(() => {
                                    return ({ projectName: event.target.value });
                                  }))}
                                >

                                </TextField>
                                <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt' }}>
                                  AI crafts text that matches the project's chosen education level and tone.
                                </Typography>
                                <Stack direction='row' spacing={2} >
                                  <FormControl fullWidth sx={{ minWidth: 150 }}>
                                    <InputLabel id="text-level-select-label2">Text Level</InputLabel>
                                    <Select
                                      variant='outlined'
                                      label="Text Level"
                                      labelId='text-level-select-label2'
                                      id='text-level-select2'
                                      value={this.state.projectlevel}
                                      onChange={(event) => { this.setState(() => { return { projectlevel: event.target.value } }) }}

                                    >
                                      {this.state.levels.map((level) => (
                                        <MenuItem key={'select2' + level} value={level}>{level}</MenuItem>

                                      ))}

                                    </Select>

                                  </FormControl>
                                  <FormControl fullWidth sx={{ minWidth: 150 }}>
                                    <InputLabel id="text-tone-select-label2">Text Tone</InputLabel>
                                    <Select
                                      variant='outlined'
                                      label="Text Tone"
                                      labelId='text-tone-select-label2'
                                      id='text-tone-select2'
                                      value={this.state.projecttone}
                                  
                                      onChange={(event) => { this.setState(() => { return { projecttone: event.target.value } }) }}

                                    >
                                      {this.state.tones.map((tone) => (
                                        <MenuItem key={'select2' + tone} value={tone}>{tone}</MenuItem>

                                      ))}
                                    </Select>

                                  </FormControl>
                                </Stack>
                                <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '5px', mb: 0 }}>
                                  Use Tags to pinpoint the key theme(s) that define your project's essence (upto 5).
                                </Typography>
                                <Stack direction='row' spacing={0} sx={{ mt: '0px' }}>
                                  
                                  <TextField
                                    sx={{ width: 150, mt: 0 }}
                                    variant="outlined"
                                    label="tag"
                                   
                                    value={this.state.tagText}
                                    onChange={(event) => (this.setState(() => {
                                      return ({ tagText: event.target.value });
                                    }))}
                                  >

                                  </TextField>
                                  <Box sx={{ mt: '10px' }}>
                                    <Tooltip title="Add Tag">
                                      <IconButton onClick={() => {

                                        this.setState({ projectTags: this.state.projectTags.concat(this.state.tagText) }, () => {
                                          this.setState({ tagText: '' })
                                        });
                                      }}
                                        disabled={this.state.projectTags.length ==5}
                                      >
                                        <SendIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Stack>
                                <Stack variant="row" spacing={0}>
                                  <Typography variant='caption'>Tags:</Typography>
                                  <Box sx={{ border: 1, height: '50px' }} >
                                    {this.state.projectTags.map((tag, index) => (
                                      <Chip label={tag} onDelete={() => {

                                        const secs = this.state.projectTags;
                                        secs.splice(index, 1);
                                        this.setState({ projectTags: secs });
                                      }} />
                                    ))}
                                  </Box>
                                </Stack>
                              </Stack>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => { this.setState(() => { return { projectEditDialog: 0 } }) }}>Cancel</Button>
                            <Button onClick={() => this.UpdateProjects(project.project_id)} >Save</Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                      <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                        <Typography variant='caption'>Level: {project.level}</Typography>
                        <Typography variant='caption'>Tone: {project.tone}</Typography>
                      </Stack>
                    </Stack>
                    <div>
                      <Stack spacing={1}>
                        <Stack direction="row" spacing={1}>
                          <Typography variant='body2'>My Tags:</Typography>
                          <Stack direction="row" spacing={1}>
                        
                            {project.tags.map(tag => (
                              <Chip key={'tag' + tag} label={tag} color="primary" size="small" />
                            ))}
                          </Stack>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography variant='body2'>AI Tags:</Typography>
                          <Stack direction="row" spacing={1}>
                            {project.aitags.map(tag => (
                              <Chip key={'aitag' + tag} label={tag} size="small" />
                            ))}
                          
                          </Stack>
                        </Stack>
                      </Stack>
                    </div>
                  </Stack>
                 
                                 
                 
                </Stack>
              
              ))}
            
          </div>
          <div className='Page-Main'>
            
            {this.state.projects.length <= 0 &&
              <Stack variant="row" spacing={2} sx={{ alignItems: 'center' }}>
                <Typography variant="caption" sx={{ textAlign: 'center', color: 'steelblue', mb: '5px', fontSize: '14pt' }}>
                  To begin, you'll need to make a project. Everything,
                  including AI-generated content and resources, is kept within these projects.<br /> You can use the <AddCircleOutlineIcon fontSize='5pt' /> button next to Projects or click the following:
                </Typography>
             
                <Button sx={{ width: '225' }} size="small" color="primary" variant="contained" endIcon={<AddCircleOutlineIcon />} onClick={(event) => {
                  
                  this.setState(() => { return { projectDialog: 1 } });
                  this.ClearProject();
                }} >Add new Project</Button>
              </Stack>
            }
            {this.state.projects.length > 0 &&
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={this.state.value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth">
                      <Tab label="Research Agent" value="0" icon={<SmartToyIcon />} iconPosition='end' />
                      <Tab label="Resources" value="1" icon={<LibraryBooksIcon />} iconPosition='end' />                      
                      <Tab label="Idea Exploration" value="2" icon={<LightbulbIcon />} iconPosition='end' disabled={this.state.hasResources == false} />
                      <Tab label="Ready-To-Use" value="3" icon={<ShareIcon />} iconPosition='end' disabled={this.state.documents.length <= 0} />                      
                    </TabList>
                    
                  </Box>
                  <TabPanel value="0" >
                    <ResearchAgent token={this.state.token} project={this.state.project} switchTab={handleSwitchTab}    />
                  </TabPanel>
                  <TabPanel value="1">
                    <Resources token={this.state.token} projectId={this.state.projectId} SetHasResource={SetHasResource} />
                  </TabPanel>
                 
                  <TabPanel value="2">                  
                    
                    
                    <Content genContent={this.state.responses} token={this.state.token} project_id={this.state.projectId} RetrieveContent={RetrieveContentHandle} SwitchTab={handleSwitchTab} CreateContent={CreateContent} project={this.state.project} />
                    
                  </TabPanel>
                  <TabPanel value="3" >
                    <Document gendocuments={this.state.documents} token={this.state.token} RetrieveDocuments={RetrieveDocumentsHandle} />
                    
                  </TabPanel>
                  
                
                </TabContext>
              
              </Box>
            }
          </div>
        </header>
      </div>
    );
  }
}

export default App;
