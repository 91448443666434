import React, { useState } from 'react';
import { Box, Button, ListItem, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PestControlIcon from '@mui/icons-material/PestControl';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';


export default function Bugs({ token }) {
    const [openBugsDialog, setOpenBugsDialog] = useState(0);
    const [bugs, setBugs] = useState([]);


    const handleRetrieveBugs = () =>
    {
        fetch(process.env.REACT_APP_API_HOST + '/support/bugs/' + token)
            .then((response) => response.json())
            .then((b) => {
                setBugs(b);
                setOpenBugsDialog(1);
            }
            );
        }

    return (
        <Tooltip title="Check out known service Bugs">
            <IconButton sx={{ width: 30, height: 30, p: '0px', m: '0px' }} >
                <PestControlIcon sx={{ color: green[400] }} onClick={() => { handleRetrieveBugs() }} />
                <Dialog open={openBugsDialog==1 }>
                    
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        Currently Known Service Bugs: <IconButton sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}>
                            <CancelIcon onClick={() => { setOpenBugsDialog(0) }} />
                        </IconButton>
                    </DialogTitle>
                    
                    <DialogContent>
                        <DialogContentText>
                            {bugs.length > 0 &&
                                <ul>
                                    {bugs.map((bug) => (
                                        <li>
                                            <Typography sx={{mb:0}} variant='h6'>
                                                {bug['name']}
                                            </Typography>
                                            
                                            <Typography sx={{ mt: 0 }} variant='caption'>
                                                {bug['desc']}
                                            </Typography>
                                            
                                        </li>

                                    ))}

                                </ul>
                            }
                        </DialogContentText>
                        
                    </DialogContent>
                </Dialog>
                
            </IconButton>
        </Tooltip>
    )

}