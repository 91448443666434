import React, { useState } from 'react';
import { Box, Button, ListItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';


export default function Feedback({ user_id, token }) {
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(0);
    const [feedbackText, setFeedbackText] = useState('');
    const [feedbackType, setFeedbackType] = useState('suggestion');
    const [feedbackError, setFeedbackError] = useState('');

    const handleSaveFeedbackClick = (event) => {

        {
            feedbackText == '' &&
    setFeedbackError('Please include your feedback comments.')}
    
        {
            feedbackText != '' &&
            fetch(process.env.REACT_APP_API_HOST + '/support/feedback/create/' + token, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'user_id': user_id, 'type': feedbackType, 'text': feedbackText })
            })
                .then((response) => response.ok)
                .then(() => {
                    setFeedbackText('');
                    setOpenFeedbackDialog(0);
                    setFeedbackType('suggestion');

                }
                );
        }
    };

    return (
        
        <Box sx={{m:0, p:0}}>
            <Dialog open={openFeedbackDialog != 0} onClose={() => { setOpenFeedbackDialog(0); setFeedbackError(''); }}>
                <DialogTitle>Submit Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Share your valuable ideas or any stumbling blocks you've come across. Your input drives us forward!
                    </DialogContentText>
                    {feedbackError != '' &&
                        <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt', mt: '10px', textAlign:'center' }}>
                        {feedbackError}
                    </Typography>
}
                    <Stack sx={{ mt: '5px' }}>
                        <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '10px', mb: 0 }}>
                            Pick the type of feedback you're after:
                        </Typography>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={feedbackType}
                            label="Feedback Type"
                            sx={{ width: 500 }}
                            onChange={(event) => { setFeedbackType(event.target.value) }}
                        >
                            <MenuItem value='suggestion'>Suggestion</MenuItem>
                            <MenuItem value='issue'>Issue</MenuItem>
                        </Select>
                        <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '10px', mb: 0 }}>
                            Kindly share your thoughts and comments with us. Your input matters!
                        </Typography>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Comment"
                            multiline
                            maxRows={10}
                            type="text"
                            sx={{ width: 500 }}
                            spellcheck="true"
                            value={feedbackText}
                            onChange={(event) => { setFeedbackText(event.target.value) }}
                        ></TextField>


                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenFeedbackDialog(0) }}>Cancel</Button>
                    <Button onClick={() => { handleSaveFeedbackClick() }}>Save</Button>
                </DialogActions>
            </Dialog>
            <Tooltip title="Send suggestions or issues">
            <Button sx={{mb:'10px'}} size="small" variant="contained" endIcon={<RateReviewOutlinedIcon />}  onClick={() => { setOpenFeedbackDialog(1) }}>
                Feedback
            
            
                </Button>
            </Tooltip>
        </Box>
     
        
    )

}