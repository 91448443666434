import React, { useState } from 'react';
import { Button, ListItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Box from '@mui/material/Box';
import { purple, pink } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelIcon from '@mui/icons-material/Cancel';
import CardMedia from '@mui/material/CardMedia';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import validator from 'validator'
import LinearProgress from '@mui/material/LinearProgress';

export default function Document({ gendocuments, token, RetrieveDocuments }) {
    const [documentName, setDocumentName] = useState('')
    const [doucmentContent, setDoucmentContent] = useState('')
    const [docDialogEdit, setDocDialogEdit] = useState(0);
    const [isError, setIsError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const allowedTypes = ["image/jpeg", "image/png"];
    const [isImageError, setIsImageError] = useState(false)
    const [errorImageMsg, setErrorImageMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [imageEditDialog, setImageEditDialog] = useState(0)
    const [editImageType, setEditImageType] = useState('file')
    const [resourceFile, setResourceFile] = useState();
    const [resourceFileBase64, setResourceFileBase64] = useState();
    const [imageUrl, setImageUrl] = useState('');


    const handleDocumentEdit = (document_id, title, text) => {
        setDocDialogEdit(document_id);
        setDocumentName(title);
        setDoucmentContent(text);
        setIsError(false);
        setErrorMsg("");
        setIsImageError(false);
        setErrorImageMsg("");

    }


    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        setIsLoading(true);
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        if (allowedTypes.includes(selectedFile.type) == false) {
            setIsImageError(true);
            setErrorImageMsg("Only JPEG and PNG images are allowed.");
            return;
        }
        setIsImageError(false);
        setResourceFile(selectedFile);
       

        getBase64(selectedFile, (result) => {
            setResourceFileBase64(result);
            setIsLoading(false);
        });

       

    };

    const handleEditImage = (document_id) =>
    {
        setImageEditDialog(document_id);
        setImageUrl('')
        setIsImageError(false)
        setErrorImageMsg('')
    }
    

    const handleDocumentImageUpdate = (document_id) =>
    {
       
        if (editImageType == 'web' & imageUrl.trim() == '') {
            setIsImageError(true)
            setErrorImageMsg('Missing Web URL')     
           
            return;
        }

        if (editImageType == 'web' & validator.isURL(imageUrl) != true) {
            setIsImageError(true)
            setErrorImageMsg('Not a valid  URL')
            return;
        }

        if (editImageType == 'file' && isError) {
            return;
        }

        if (editImageType == 'file')
        {
            setImageUrl('')
        }
        
        if (editImageType != 'file') {
            setIsImageError(false)
            setResourceFile()
            setResourceFileBase64();
        }

        fetch(process.env.REACT_APP_API_HOST + '/document/updateimage/'  + token, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'document_id': imageEditDialog, 'image_url': imageUrl, 'image_bytes': resourceFileBase64 })
        })
            .then((response) => response.ok)
            .then(() => {

                
                setImageEditDialog(0)
                setEditImageType('file')
                RetrieveDocuments();

            }
            );
        }

    
    const handleDocumentUpdate = (event) => {

        if (documentName.trim() == '') {
            setIsError(true)
            setErrorMsg('Title cannot be empty')
            return
        }

        if (doucmentContent.trim() == '') {
            setIsError(true)
            setErrorMsg('Content cannot be empty')
            return
        }

        

        fetch(process.env.REACT_APP_API_HOST + '/document/update/' + token, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'document_id': docDialogEdit, 'name': documentName, 'content': doucmentContent })
        })
            .then((response) => response.ok)
            .then(() => {

                setDocumentName('');
                setDoucmentContent('');
                setDocDialogEdit(0);
                RetrieveDocuments();

            }
            );
    }


    const refreshDocumentImage = (document_id) => {

        fetch(process.env.REACT_APP_API_HOST + '/document/refreshimage/' + document_id + '/' + token)
            .then((response) => response.ok)
            .then(() =>
                RetrieveDocuments()

            );
    };


    const imageSwitch = (document) => {
        switch (document.type) {

            case 'Post':
                switch (document.subtype) {
                    case 'Twitter':
                        return <Box sx={{ position: 'relative' }}>
                            <IconButton sx={{ position: 'absolute', right: 0 }} onClick={() => { refreshDocumentImage(document.document_id) }}>
                                <RefreshIcon color="action"></RefreshIcon>
                            </IconButton>
                            <img src={require("./../Images/twitter.jpg")} className='centered-and-cropped' width='240px' height='194px'   >

                            </img>
                        </Box>;


                    case 'Facebook':
                        return <CardMedia
                            component="img"
                            height="194"
                            image={require("./../Images/facebook.jpg")}
                            alt="Paella dish"
                        />;
                    case '':
                        return <CardMedia
                            component="img"
                            height="194"
                            image={require("./../Images/social_media.jpg")}
                            alt="Paella dish"
                        />;
                }
            case 'Blog':
                return <CardMedia
                    component="img"
                    height="194"
                    image={require("./../Images/blog.jpg")}
                    alt="Paella dish"
                />;
        }

    }

    const DeleteDocument = (document_id) => {
        fetch(process.env.REACT_APP_API_HOST + '/document/delete/' + document_id + '/' + token)
            .then((response) => response.ok)
            .then(() =>
                RetrieveDocuments()
            );
    }

    const DownloadDocument = (document_id) => {
        fetch(process.env.REACT_APP_API_HOST + '/document/download/' + document_id + '/' + token)
            // 1. Convert the data into 'blob'
            .then((response) => response.blob())
            .then((blob) => {

                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${document_id}.pdf`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }


  

    return (
        <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
            <Dialog open={imageEditDialog!=0} onClose={() => { handleEditImage(0)}}>
                
                <DialogContent>
                    <DialogContentText>
                        <Stack spacing={1}>
                            {isImageError &&
                                <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt' }}>
                                    {errorImageMsg}
                                </Typography>
                            }
                            <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt' }}>
                                Would you like to upload and image or use a web URL?
                            </Typography>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={editImageType}
                                label="Image Type"
                                onChange={(event) => { setEditImageType(event.target.value) }}
                            >
                                <MenuItem value='file'>File</MenuItem>
                                <MenuItem value='web'>Web</MenuItem>                              
                                

                            </Select>
                            {editImageType == 'file' &&
                                <Stack>
                                    <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '10px', mb: '10px' }}>
                                        Upload an image
                                    </Typography>
                                    <input
                                        type="file"
                                        name="myImage"
                                        accept={allowedTypes}
                                        onChange={(e) => { handleFileChange(e); }}
                                    />
                                    {isImageError &&
                                        <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt' }}>
                                            {errorImageMsg}
                                        </Typography>}
                                </Stack>
                            }

                            {editImageType == 'web' &&
                                <Stack>
                                    <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '10px', mb: '5px' }}>
                                        Leverage a publicly accessible image URL.
                                    </Typography>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="weburl"
                                        label="Web Url"
                                        type="url"
                                        sx={{ width: 550 }}
                                        value={imageUrl}
                                        onChange={(event) => { setImageUrl(event.target.value) }}
                                    />
                                </Stack>
                            }
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleEditImage(0) }}>Cancel</Button>
                    <Button disabled={isLoading} onClick={() => { handleDocumentImageUpdate() }}  >Save</Button>
                </DialogActions>
                {isLoading &&
                    <LinearProgress />}
                </Dialog>
            <Dialog open={docDialogEdit != 0} onClose={() => { setDocDialogEdit(0) }}>
                
                <DialogContent>
                    <DialogContentText>
                        <Stack spacing={0}>
                            {isError &&
                                <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt', textAlign: 'center' }}>
                                    {errorMsg}
                                </Typography>
                            }
                            <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mb: 0 }}>
                                Spruce up your document's title.
                            </Typography>
                            <TextField 
                                multiline
                                maxRows={3}
                                sx={{ width: 500 }}
                                value={documentName}
                                onChange={(event) => {
                                    setDocumentName(event.target.value)
                                }}
                            ></TextField>
                            <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '10px', mb: 0 }}>
                                Give the following text a makeover – tweak it, twist it, make it your own!
                            </Typography>
                        <TextField multiline
                            maxRows={20}
                            sx={{ width: 500 }}
                            value={doucmentContent}
                            onChange={(event) => {
                                setDoucmentContent(event.target.value)
                            }}
                        >
                        
                            </TextField>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDocDialogEdit(0) }}>Cancel</Button>
                    <Button onClick={(event) => handleDocumentUpdate(event)} >Save</Button>
                </DialogActions>
            </Dialog>
            {gendocuments.map((gendocument, index) => (

     

                <Card key={'crd' + gendocument.document_id} sx={{ minWidth: 220, maxWidth: 250 }}>
                    <CardHeader
                        sx={{ mt: 0, pt: 1 }}
                        action={
                            <Box sx={{ pt: 1 }}>

                                {gendocument.type == 'Document' &&
                                    < IconButton sx={{ p: '3px' }} aria-label="download"  >
                                        <FileDownloadIcon sx={{ fontSize: 20 }} onClick={() => { DownloadDocument(gendocument.document_id) }} />
                                    </IconButton>
                                }
                                {gendocument.type == 'Essay' &&
                                    < IconButton sx={{ p: '3px' }} aria-label="download"  >
                                        <FileDownloadIcon sx={{ fontSize: 20 }} onClick={() => { DownloadDocument(gendocument.document_id) }} />
                                    </IconButton>
                                }
                                <Tooltip title="Edit">
                                <IconButton sx={{ p: '3px' }} aria-label="edit-document"  >
                                    <EditIcon sx={{ fontSize: 20 }} onClick={() => { handleDocumentEdit(gendocument.document_id, gendocument.name, gendocument.content) }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                <IconButton sx={{ p: '3px' }} aria-label="delete"  >
                                    <DeleteIcon sx={{ fontSize: 20 }} onClick={() => { DeleteDocument(gendocument.document_id) }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }
                        title={<Stack sx={{ mt: 0 }}>
                            {gendocument.type == 'Post' &&
                                <Typography sx={{ fontFamily: 'cursive', color: 'silver' }}>

                                    {gendocument.subtype}

                                </Typography>}
                            
                            {gendocument.type == 'Document' &&
                                <Typography sx={{ fontFamily: 'cursive', color: 'silver' }}>

                                    {gendocument.subtype}

                                </Typography>}

                            {gendocument.type == 'Blog' &&
                                <Typography sx={{ fontFamily: 'cursive', color: 'silver' }}>
                                    {gendocument.type}
                                </Typography>}


                            {gendocument.name}
                        </Stack>}
                        titleTypographyProps={{ sx: { fontSize: 15, textAlign: 'left', mt: 0 }, color: "text.secondary" }}
                    />

                    {gendocument.image_bytes.trim() != '' &&
                        < Box sx={{ position: 'relative', textAlign: 'center' }}>
                            <Stack direction={'row'} spacing={1} sx={{ position: 'absolute', right: 0, mr: '10px', mt: '5px' }}>
                                <Tooltip title="Random refresh image">
                                    <IconButton sx={{ m: 0, p: 0 }} onClick={() => { refreshDocumentImage(gendocument.document_id) }}>
                                        <RefreshIcon sx={{ color: pink[500] }}></RefreshIcon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit image">
                                    <IconButton sx={{ m: 0, p: 0 }} onClick={() => { handleEditImage(gendocument.document_id) }} >
                                        <EditIcon sx={{ color: pink[500] }}></EditIcon>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            < img src={gendocument.image_bytes}  className='centered-and-cropped' width='240px' height='194px' />
                    </Box>
                    }

                    {gendocument.image_bytes == '' &&
                        gendocument.image_url == '' &&
                        imageSwitch(gendocument)

                    }

                    {gendocument.image_url.trim() != '' &&
                        <Box sx={{ position: 'relative', textAlign:'center' }}>
                            <Stack direction={'row'} spacing={1} sx={{ position: 'absolute', right: 0,mr:'10px', mt:'5px' }}>
                                <Tooltip title="Random refresh image">
                                <IconButton sx={{ m: 0, p: 0 }} onClick={() => { refreshDocumentImage(gendocument.document_id) }}>
                                <RefreshIcon sx={{ color: pink[500]}}></RefreshIcon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit image">
                                    <IconButton sx={{ m: 0, p: 0 }} onClick={() => { handleEditImage(gendocument.document_id)}} >
                                    <EditIcon sx={{ color: pink[500] }}></EditIcon>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <img src={gendocument.image_url} className='centered-and-cropped' width='240px' height='194px'   >

                            </img>
                        </Box>
                    }


                    <CardContent sx={{ textAlign: 'left' }}>
                        <Typography variant="body2" color="text.secondary">
                            {gendocument.content}
                        </Typography>

                    </CardContent>

                </Card>
            ))
            }</Stack>
    )
}