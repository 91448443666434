import React, { useState ,useEffect} from 'react';
import { Button, ListItem } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { IoIosAddCircle } from "react-icons/io";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import './Resources.css';
import RefreshIcon from '@mui/icons-material/Refresh';
import validator from 'validator'
import LinearProgress from '@mui/material/LinearProgress';
import ImageIcon from '@mui/icons-material/Image';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LanguageIcon from '@mui/icons-material/Language';
import { orange, pink,purple } from '@mui/material/colors';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Box from '@mui/material/Box';

export default function Resources({ token, projectId, SetHasResource }) {

  const [openResourceDialog, setOpenResourceDialog] = useState(0);
  const [resourceEdiitDialog, setResourceEdiitDialog] = useState(0);
  const [resourceText, setResourceText] = useState('');
  const [resourceName, setResourceName] = useState('');
  const [resourceError, setResourceError] = useState('');
  const [resourceType, setResourceType] = useState('text');
  const [resourceReference, setResourceReference] = useState('');
  const [resourceFile, setResourceFile] = useState();
  const [resourceFileBase64, setResourceFileBase64] = useState();
  const allowedImageTypes = ["image/jpeg", "image/png"];
  const alloweddocTypes = ["text/plain", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [docuDialog,setDocuDialog] = useState(false)
  const [refImage, setRefImage] = useState('')
  const [agentDocuDialog, setAgentDocuDialog] = useState('')
  const [resources, setResources] = useState([])
  const refreshTime = 20000 //How frequently you want to refresh the data, in ms


  const RetrieveResources = () => {
    fetch(process.env.REACT_APP_API_HOST + '/resources/' + projectId + '/' + token)
      .then((response) => response.json())
      .then((res) => {
        setResources(res);

        SetHasResource(res.filter(resource => resource.status == 'ready').length > 0);
  }
        //this.setState(() => {
         // return { resources: resources }
       // })
      );
  }

  const refreshresources = () => {
    if (resources.length > 0) {
      var items = resources.filter(r => r.status != 'ready' && r.status != 'failed');
      //console.log(agentRequests); 
      if (items.length > 0) {

        RetrieveResources();
      }
    }


  }

  useEffect(() => {
    RetrieveResources();

  }, [projectId])


  useEffect(() => {
    const comInterval = setInterval(refreshresources, refreshTime); //This will refresh the data at regularIntervals of refreshTime
    return () => clearInterval(comInterval) //Clear interval on component unmount to avoid memory leak

  }, [resources])

  const retrieve_ref_image = (image_id) => {
    if (image_id) {
      fetch(process.env.REACT_APP_API_HOST + '/agent/request/result/image/' + token + '/' + image_id)
        .then((response) => response.json())
        .then((resp) => {
          return (
            setRefImage(resp['data'])


          )
        }
        );
    }
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    setIsLoading(true);
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
      
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleFileChange = (e, isImage) => {
    const selectedFile = e.target.files[0];
    
    if (isImage == true) {
      if (allowedImageTypes.includes(selectedFile.type) == false) {
        setIsError(true);
        setErrorMsg("Only JPEG and PNG images are allowed.");
        return;
      }
    }
    else
    {
      if (alloweddocTypes.includes(selectedFile.type) == false) {
        setIsError(true);
        setErrorMsg("Only Text and Docx files are allowed.");
        return;
      }
      }


    setIsError(false);
    setResourceFile(selectedFile);
    setResourceReference(selectedFile.name)

    getBase64(selectedFile, (result) => {
      setResourceFileBase64(result);
      setIsLoading(false);
    });
   
  
    
  };


 


  const handleClickOpenNewResource = () => {
    setResourceText('');
    setResourceName('');
    setResourceReference('');
    setResourceError('')
    setResourceType('text');
    setOpenResourceDialog(1);
  };
  
  const handleCloseNewResource = () => {
    setOpenResourceDialog(0);
  };

  const rowStatus = (status) =>
  {
    switch (status) {

      case 'failed':
        return 'error'
      case 'new':
        return 'primary'
      case 'processing':
        return  orange[250]
    }
    }

  const handleSaveResourceClick = (event) => {
    
  
   
    if (resourceType == 'text' & resourceText.trim() == '') {
      setResourceError('Missing Resource Text');
      return;
    }
    
    
    if (resourceType == 'web' & validator.isURL(resourceReference) != true) {
      setResourceError('Not a valid Web URL');
      return;
    }

    if (resourceType == 'web' & resourceReference.trim() == '') {

      setResourceError('Missing Web URL');
      return;
    }
  
    
    if (resourceName.trim() == '') {
      
      setResourceError('Missing Resource Name');
      return;
    }

    if (resourceType == 'image' && isError) {
      return;
    }

    if (resourceType == 'doc' && isError) {
      return;
    }
  
    if (resourceType in ['web','text']) {
      setIsError(false)
      setResourceFile()
      setResourceFileBase64();
    }

   

    fetch(process.env.REACT_APP_API_HOST + '/resource/create/' + projectId + '/' + token, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'content': resourceText, 'name': resourceName, 'type': resourceType, 'reference': resourceReference, 'resourcefile': resourceFileBase64 })
    })
      .then((response) => response.ok)
      .then(() => {
            
        setResourceText('');
        setResourceName('');
        setOpenResourceDialog(0);
        setResourceType('text');
        setResourceReference('');
        RetrieveResources();
        setResourceError('');
            
      }
      );
    
  };

  const DeleteResource = (resource_id) => {
    fetch(process.env.REACT_APP_API_HOST + '/resource/delete/' + resource_id + '/' + token)
      .then((response) => response.ok)
      .then(() =>
        RetrieveResources()
      );
  }

  const handleUpdateResourceClick = (event, resource_id,subtype) => {
    
    {
       resourceText.trim() == '' &&

        setResourceError('Missing  Text');
    }
    

    {
      resourceName.trim() == '' &&

        setResourceError('Missing Resource Name');
    }
    
    {
      resourceName != '' & resourceText != '' &&

        fetch(process.env.REACT_APP_API_HOST + '/resource/update/' + projectId + '/' + token, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 'content': resourceText, 'name': resourceName, 'resource_id': resource_id, 'subtype': subtype })
        })
          .then((response) => response.ok)
          .then(() => {
            
            setResourceText('');
            setResourceName('');
            setResourceReference('');
            setResourceEdiitDialog(0);
            RetrieveResources();
  
          }
          );
    }
  };

  return (
    <div className='Project-Resource' >
      <Dialog open={agentDocuDialog != ''} sx={{ m: '0px', p: '0px' }}>
                <DialogActions sx={{ m: '0px', p: '0px' }}>
                    <IconButton>
                        <CancelIcon onClick={() => { setAgentDocuDialog('') }} />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ m: '0px', p: '0px' }}>
                    <DialogContentText sx={{ m: '0px', p: '0px' }}>
                        {retrieve_ref_image(agentDocuDialog)}
                        < img src={refImage} width={'600px'} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
      <Typography variant="caption" sx={{ textAlign: 'center', color: 'steelblue', mb:'5px', fontSize:'12pt' }}>
        To make the most of your AI assistant, remember to include resources like copied text, notes, web links, and other helpful content.
      </Typography>
      <Button sx={{ width: '220px', textAlign: 'center' }}  size="small" color="primary" variant="contained" endIcon={<IoIosAddCircle />} onClick={handleClickOpenNewResource} >Add Resource</Button>
      <Dialog open={openResourceDialog == 1} onClose={handleCloseNewResource}>
        <DialogTitle>Add a Resource</DialogTitle>
        <DialogContent>          
          <Stack>
            <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt' }}>
              {resourceError}
            </Typography>
            <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt' }}>
              Choose the resource that's about to join the fun!
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={resourceType}
              label="Resource Type"
              onChange={(event) => { setResourceType(event.target.value) }}
            >
              <MenuItem value='text'>Text</MenuItem>
              <MenuItem value='doc'>Document</MenuItem>
              <MenuItem value='web'>Web</MenuItem>
              <MenuItem value='image'>Image</MenuItem>
                        
            </Select>
            <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt:'10px', mb:0 }}>
              Bestow upon it a name that carries significance.
            </Typography>
            <TextField
              required
              margin="dense"
              id="name"
              label="Name"
              type="text"
              sx={{ width: 550, mt:0 }}
              spellcheck="true"
              value={resourceName}
              onChange={(event) => { setResourceName(event.target.value) }}
              
            ></TextField>
                      
            {resourceType == 'text' &&
              <Stack>
            <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt:'10px', mb:0 }}>
                  Unleash your creativity: either paste in captivating text or craft your own literary masterpiece.
            </Typography>
                <TextField
                  required
                autoFocus
                margin="dense"
                id="resourcetext"
                label="Resource Text"
                type="text"
                sx={{ width: 550 }}
                multiline
                value={resourceText}
                onChange={(event) => {
                  setResourceText(event.target.value)
                }}
                maxRows={8}
                />
              </Stack>
            }
            {resourceType == 'web' &&
              <Stack>
                <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '10px', mb: '5px' }}>
                  Leverage a publicly accessible URL that's not only valid, but also a haven of cleanliness and safety.
                </Typography>
              <TextField
                autoFocus
                margin="dense"
                id="weburl"
                label="Web Url"
                type="url"
                sx={{ width: 550 }}
                value={resourceReference}
                onChange={(event) => { setResourceReference(event.target.value) }}
                />
                </Stack>
            }

            {resourceType == 'image' &&
              <Stack>
                <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '10px', mb: '10px' }}>
                  Retrieve text from your uploaded image.
                </Typography>
              <input
                type="file"
              name="myImage"
              accept={allowedImageTypes}
                  onChange={(e) => { handleFileChange(e,true); }}
              />
              {isError &&
              <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt' }}>
              {errorMsg}
            </Typography>}
</Stack>
            }
            
            {resourceType == 'doc' &&
              <Stack>
                <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '10px', mb: '10px' }}>
                  Retrieve text from your uploaded document.
                </Typography>
                <input
                  type="file"
                  name="myDocument"
                  accept={alloweddocTypes}
                  onChange={(e) => { handleFileChange(e, false); }}
                />
                {isError &&
                  <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt' }}>
                    {errorMsg}
                  </Typography>}
              </Stack>
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewResource}>Cancel</Button>
          <Button onClick={handleSaveResourceClick} disabled={isLoading}>Save</Button>
        </DialogActions>
        {isLoading &&
        <LinearProgress/>}
      </Dialog>

      <br />
      <div className='Resources-Div'>        
        <Typography variant="caption" sx={{ textAlign: 'center', alignItems:'center', color:"grey" ,mr:"5px", fontSize:'11pt'}}>
          It may take 1 to 2 minutes to pull content from uploaded documents and web sites.
        </Typography>
       
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Resource</TableCell>
                <TableCell align="center">Type</TableCell>
                
                <TableCell align="left">Reference</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Tokens</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  
                  <TableCell align="center">
                    {row.type == 'web' && 
                      row.subtype == 'text' &&
                        <Tooltip title='Web'>
                          <LanguageIcon color="primary"/>
                        </Tooltip>
                    }

                    {row.type == 'web' &&
                      row.subtype == 'web' &&
                      <Tooltip title='Web'>
                        <LanguageIcon color="primary" />
                      </Tooltip>
                    }
                   
                      {row.subtype == 'video' &&
                      <Tooltip title='Video'>
                        <VideoCameraBackIcon color="success" />
                      </Tooltip>
                    }
                    {row.subtype == 'vectordb' &&
                      <Tooltip title='Reference'>
                        <MenuBookIcon sx={{ color: purple[500] }} />
                      </Tooltip>
                    }
                    

                    {row.subtype == 'image' &&
                      <Tooltip title='Image'>
                        <ImageIcon sx={{ color: pink[500] }} />
                      </Tooltip>}
                    
                    {row.type == 'text' &&
                      <Tooltip title='Text'>
                        <TextSnippetIcon sx={{ color: orange[500] }} />
                      </Tooltip>
                    }

                    {row.type == 'doc' &&
                      <Tooltip title='Document Text'>
                        <TextSnippetIcon sx={{ color: purple[500] }} />
                      </Tooltip>
                    }

                  </TableCell>
                  <TableCell align="left">

                    {row.type == 'file' &&
                      <div>                   
                          
                       
                        <Link href='#' onClick={() => { setDocuDialog(true) }}>{row.reference}</Link>
                        <Dialog open={docuDialog}> 
                          <DialogActions>
                            <IconButton>
                              <CancelIcon onClick={ ()=>{setDocuDialog(false)}} />
                          </IconButton>
                          </DialogActions>    
                      <DialogContent>
                          <DialogContentText>
                        <img src={row.resourcefile} width={'500px'}/>
                            </DialogContentText>
                      </DialogContent>
                      </Dialog>
                      </div>
                      
}
                    { row.type == 'web' && <a href={row.reference} target="_blank" rel="noopener noreferrer">{row.reference}
                      <OpenInNewIcon sx={{ fontSize: 10 }} />
                    </a>}
                    {row.subtype == 'vectordb' &&
                      <Box sx={{ m: '0px', p: '0px' }}>
                        <Link href='#' onClick={() => { setAgentDocuDialog(row.ref_id) }}>{row.reference}
                          <OpenInNewIcon sx={{ fontSize: 10 }} /></Link>

                      </Box>

                    }
                    
                  </TableCell>
                  <TableCell align="right" sx={{ color: 'success.dark' }}>
                    
                    <Typography color={rowStatus(row.status) } >                
                      
                      {row.status}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">{row.created_date}</TableCell>
                  <TableCell align="left">{row.tokens}</TableCell>
                  <TableCell>
                    {row.status == 'ready' &&
                      <IconButton aria-label="edit" size="small" onClick={() => {
                        setResourceText(row.content);
                        setResourceName(row.name);
                        setResourceEdiitDialog(index + 1);
                      }}>
                        <Tooltip title="Edit Resource"><EditIcon fontSize="small" /></Tooltip>
                      </IconButton>
                    }
                    <IconButton aria-label="delete" size="small" onClick={() => { DeleteResource(row.resource_id) }}>
                      <Tooltip title="Delete Resource"><DeleteIcon fontSize="small" /></Tooltip>
                    </IconButton>
                    <Dialog open={resourceEdiitDialog == index + 1} onClose={() => { setResourceEdiitDialog(0) }}>
                      <DialogTitle>Edit Resource</DialogTitle>
                      
                      <DialogContent>
                        <DialogContentText>
                          <Stack>
                            <Typography variant='subtitle1' sx={{ color: 'firebrick', fontSize: '10pt' }}>
                              {resourceError}
                            </Typography>
                          
                          <TextField
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            sx={{ width: 500 }}
                            spellcheck="true"
                            value={resourceName}
                            onChange={(event) => { setResourceName(event.target.value) }}
                            ></TextField>
                            <Typography variant='subtitle1' sx={{ color: 'grey', fontSize: '10pt', mt: '10px', mb: 0 }}>
                              Polish up your text to assist the AI in conjuring impeccably refined results:
                            </Typography>
                          <TextField multiline
                            maxRows={20}
                            sx={{ width: 500 }}
                            value={resourceText}
                            onChange={(event) => {
                              setResourceText(event.target.value)
                            }}
                          >

                            </TextField>
                          </Stack>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => { setResourceEdiitDialog(0) }}>Cancel</Button>
                        <Button onClick={(event) => handleUpdateResourceClick(event, row.resource_id, row.subtype)} >Save</Button>
                      </DialogActions>
                    </Dialog>
                               
                  </TableCell>
                </TableRow>
                            
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </div>
                 
    </div>
  )



}